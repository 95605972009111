import React, { useState, useEffect, useRef } from "react";
import "../css/mobile.css";
import TeamMobile from "../teamMobile";
import Title from "../subtitle";
import { db } from "../utils/firebase";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import FlowerGen from "../flowerGen";

const MobileAbout = () => {
  const [page, setPage] = useState("overview");
  const flowerRef = useRef(null); // Ref for previous images container

  return (
    <div className="edene-mobile-wrapper">
      <div className="background-mobile"></div>
      <Title></Title>

      <div className="edene-about-mobile">
        <div className="edene-title-mobile">About</div>
        <div className="edene-body-mobile">
          <div className="edene-step-title">Project Passfleur</div>
          The concept behind the "PASSFLEUR" project and the land of idem arises
          from the understanding that national identity plays a pivotal role in
          how we see ourselves and others. Similarly, passports have become
          tools for monitoring movement, justifying restrictions, and defining
          freedom, protection, and security. who holds the right to reside in a
          nation? To belong? To become a citizen? The answers prove to be rather
          arbitrary. With the question "IF NATIONS WERE GARDENS AND CITIZENS
          THEIR FLOWERS?" visitors are encouraged to shift their perspective and
          reflect on the criteria for citizenship and the right to belong.
        </div>
        <TeamMobile></TeamMobile>
        <div className="edene-body-mobile center andale">
          <p>
            THE PROJECT WAS MENTORED BY EVA WANDELER AND MARKUS BÜCHER. <br />
            DESIGN BACHELOR PROJECT, TRENDS & IDENTITY, UNIVERSITY OF THE ARTS
            ZURICH (ZHDK)
            <br />
            <br />
            <br />
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default MobileAbout;
