import React from "react";
import "../App.css";
import "./css/edene.css";
import "./css/essentials.css";
import "./css/about.css";
import "./css/team.css";
import Team from "./team";
import Menu from "./menu";
import Title from "./subtitle";

const About = () => {
  return (
    <div className="edene-wrapper about-wrapper about-wrapper">
      <div className="background"></div>
      <Menu></Menu>
      <Title></Title>
      <div className="edene-about-container">
        <div className="edene-about-information">
          <div className="edene-title edene-about-title">ABoUT</div>
          <div className="edene-body-text andale">
            <div className="edene-about-paragraph">
              <div className="edene-step-title">About Idem</div>
              <p>
                "IDEM" derives from the latin meaning "the same," and serves as
                the embodiment of equal opportunity for all. It stands as an
                imaginary utopian garden, functioning as a governmental entity,
                offering refuge to those seeking a new sense of belonging.
                Inspired by the philosophical doctrine of cosmopolitanism,
                Idem's governance rests on an inclusive policy, welcoming all
                who arrive through peaceful means. Idem's citizens go beyond
                mere individuals, translating people's values and principles
                into unique flowers within its garden.
              </p>
            </div>

            <div className="edene-about-paragraph">
              <div className="edene-step-title">Project Passfleur</div>
              <p>
                The concept behind Project Passfleur and the land of Idem arises
                from the understanding that national identity plays a pivotal
                role in how we see ourselves and others. Similarly, passports
                have become tools for monitoring movement, justifying
                restrictions, and defining freedom, protection, and security.
                who holds the right to reside in a nation? To belong? To become
                a citizen? The answers prove to be rather arbitrary.
              </p>
              <p>
                With the question "IF NATIONS WERE GARDENS AND CITIZENS THEIR
                FLOWERS?" visitors are encouraged to shift their perspective and
                reflect on the criteria for citizenship and the right to belong.
              </p>
            </div>

            <div className="edene-about-paragraph">
              <div className="edene-step-title">The MAking of PAssfleur</div>
              <p>
                The objectives of the "PASSFLEUR" project were to collaborate
                interdisciplinarily and integrate a variety of skill sets. The
                development of the project involved multiple components,
                including conceptual design, ux and interaction design,
                industrial design, coding, and graphics. Learn about the
                Passfleur team here below.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Team></Team>
      <div className="edene-about-paragraph center andale">
        <p>
          THE PROJECT WAS MENTORED BY EVA WANDELER AND MARKUS BÜCHER. <br />
          DESIGN BACHELOR PROJECT, TRENDS & IDENTITY, UNIVERSITY OF THE ARTS
          ZURICH (ZHDK)
          <br />
          <br />
          <br />
          <br />
        </p>
      </div>
    </div>
  );
};

export default About;
