import React, { useState, useEffect } from "react";
import "../App.css";
import "./css/essentials.css";
import { questions, answers } from "./utils/questions";
import step_1 from "../assets/images/step_1.png";
import step_2 from "../assets/images/step_2.png";
import step_3 from "../assets/images/step_3.png";
import step_4 from "../assets/images/step_4.png";
import step_5 from "../assets/images/step_5.png";
import transitionImage from "../assets/images/transition.png"; // Add your transition image here
import FlowerGen from "./flowerGen";
import { db } from "./utils/firebase";
import { collection, addDoc } from "firebase/firestore";
import MenuConfirmation from "./menuConfirmation";

// Array of step images
const stepImages = [step_1, step_2, step_3, step_4, step_5];

const Questions = ({ onData, onBoardingCompleted }) => {
  const [currentQuestionId, setCurrentQuestionId] = useState(1);
  const [userAnswers, setUserAnswers] = useState({});
  const [animationClass, setAnimationClass] = useState("fade-in");
  const [showTransitionImage, setShowTransitionImage] = useState(false);
  const [showLearnMore, setShowLearnMore] = useState(false);

  const currentQuestion = questions.find(
    (question) => question.id === currentQuestionId
  );
  const currentQuestionAnswers = currentQuestion.answer_ids.map((answerId) =>
    answers.find((answer) => answer.id === answerId)
  );

  const handleCompletion = async () => {
    const randomCode = generateRandomCode();

    // Get current date
    const currentDate = getCurrentDate();

    // Include date and random code in each SVG file object
    const svgFilesWithDetails = {
      name: onBoardingCompleted,
      date: currentDate,
      code: randomCode,
      files: svgFiles.map((file) => ({
        ...file,
      })),
    };

    onData(svgFilesWithDetails); // Send data back to Landing

    try {
      const docRef = await addDoc(collection(db, "passes"), {
        svgFiles: svgFilesWithDetails,
        date: new Date(),
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleAnswer = (questionId, answerId) => {
    setAnimationClass("fade-out");

    // Immediately update userAnswers
    setUserAnswers((prevAnswers) => {
      const updatedAnswers = {
        ...prevAnswers,
        [questionId]: { answerId, stepIndex: currentQuestionId }, // Save both answer and step index
      };
      console.log(updatedAnswers); // Log the updated state
      svgFiles = Object.entries(updatedAnswers).map(
        ([questionId, { answerId, stepIndex }]) => ({
          folder: stepIndex,
          fileName: answerId,
        })
      );
      return updatedAnswers;
    });

    setTimeout(() => {
      setShowTransitionImage(true);
      setAnimationClass("fade-in-image");

      setTimeout(() => {
        setAnimationClass("fade-out-image");

        setTimeout(() => {
          setShowTransitionImage(false);

          const nextQuestionId = questionId + 1;
          if (questions.some((question) => question.id === nextQuestionId)) {
            setCurrentQuestionId(nextQuestionId);
          } else {
            console.log("No more questions.");
            handleCompletion(); // Save to Firestore
          }

          setAnimationClass("fade-in");
        }, 500); // Match this duration with the fade-out image animation duration
      }, 2000); // Show the image for 2 seconds
    }, 500); // Match this duration with the fade-out animation duration
  };

  const handleLearnMore = () => {
    setShowLearnMore(true);
  };

  const handleBackToMain = () => {
    setShowLearnMore(false);
  };

  useEffect(() => {
    if (animationClass === "fade-in") {
      const timer = setTimeout(() => {
        setAnimationClass("");
      }, 500); // Match this duration with the fade-in animation duration
      return () => clearTimeout(timer);
    }
  }, [animationClass]);

  useEffect(() => {
    setAnimationClass("fade-in");
  }, []);

  // Generate the svgFiles array based on userAnswers
  let svgFiles = Object.entries(userAnswers).map(
    ([questionId, { answerId, stepIndex }]) => ({
      folder: stepIndex,
      fileName: answerId,
    })
  );

  // Function to generate random code
  const generateRandomCode = () => {
    const numbers = "0123456789";
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let code = "";
    for (let i = 0; i < 8; i++) {
      code += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }
    code += letters.charAt(Math.floor(Math.random() * letters.length));
    return code;
  };

  // Function to get current date in the format: day, month, year
  const getCurrentDate = () => {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div>
      <MenuConfirmation />
      <div className="edene-wrapper">
        <div className="background"></div>
        {showLearnMore ? (
          <div className="learn-more-image-container">
            <img
              src={require(`../assets/images/${currentQuestion.learn_more}.png`)}
              alt="Learn More"
              className="learn-more-image"
            />
            <div
              className="edene-action-button learn-more"
              onClick={handleBackToMain}
            >
              Back
            </div>
          </div>
        ) : (
          <div>
            <div className="edene-progress-map">
              {questions.map((question, index) => (
                <div
                  key={question.id}
                  id={`step_${question.id}`}
                  className={`edene-step ${
                    currentQuestionId === question.id ? "active" : ""
                  }`}
                >
                  <img
                    className={`edene-step-icon ${
                      currentQuestionId === question.id ||
                      (index === currentQuestionId - 1 &&
                        animationClass !== "fade-out")
                        ? "active"
                        : ""
                    }`}
                    src={stepImages[question.id - 1]} // Use the image corresponding to the question ID
                    alt=""
                  />
                  {currentQuestionId === question.id &&
                    !showTransitionImage && (
                      <div className={`edene-step-title ${animationClass}`}>
                        {question.name.replace(/^Q0\d\s*/, "")}
                      </div>
                    )}
                </div>
              ))}
            </div>

            {showTransitionImage ? (
              <div className={`transition-image-container ${animationClass}`}>
                <FlowerGen svgFiles={svgFiles} scaleFactor={1.9} />
              </div>
            ) : (
              <div className={`edene-question-container ${animationClass}`}>
                <div className="edene-question-title">
                  {currentQuestion.title}
                </div>
                {currentQuestionAnswers.map((answer) => (
                  <div className="edene-answer" key={answer.id}>
                    <div
                      className="edene-answer-identifier"
                      onClick={() =>
                        handleAnswer(currentQuestion.id, answer.id)
                      }
                    >
                      {answer.id}
                    </div>
                    <div className="edene-answer-title">{answer.title}</div>
                  </div>
                ))}
                <div
                  className="edene-action-button learn-more"
                  onClick={handleLearnMore}
                >
                  Learn More
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Questions;
