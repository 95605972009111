import React, { useState } from "react";
import "../App.css";
import "./css/essentials.css";
import "./css/edene.css";
import Onboarding from "./onboarding";
import Questions from "./questions";
import Pass from "./pass";
import LoadingBar from "./progress";
import Menu from "./menu";
import Title from "./subtitle";

const Edene = () => {
  const [onBoarding, setOnBoarding] = useState(false);
  const [onBoardingCompleted, setOnBoardingCompleted] = useState(null);
  const [questionsCompleted, setQuestionsCompleted] = useState(null);
  const [loadingQuestions, setLoadingQuestions] = useState(false);

  const handleDataFromOnboarding = (receivedData) => {
    setOnBoardingCompleted(receivedData);
    setLoadingQuestions(true);
    setTimeout(() => {
      setLoadingQuestions(false);
    }, 3000); // 3 seconds
  };

  const handleDataFromQuestions = (receivedData) => {
    setQuestionsCompleted(receivedData);
    setLoadingQuestions(true);
    setTimeout(() => {
      setLoadingQuestions(false);
    }, 3000); // 3 seconds
  };

  return (
    <div>
      <div className="edene-wrapper" onClick={() => setOnBoarding(true)}>
        <div className="background"></div>
        <Title />

        <div>
          {onBoardingCompleted === null ? (
            <Onboarding onData={handleDataFromOnboarding} />
          ) : (
            <div>
              {questionsCompleted === null ? (
                <div>
                  {loadingQuestions ? (
                    <LoadingBar />
                  ) : (
                    <Questions
                      onData={handleDataFromQuestions}
                      onBoardingCompleted={onBoardingCompleted} // Pass onBoardingCompleted to Questions
                    />
                  )}
                </div>
              ) : (
                <div>
                  {loadingQuestions ? (
                    <LoadingBar />
                  ) : (
                    <Pass questionsCompleted={questionsCompleted} />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Edene;
