import { useEffect, useRef } from "react";

const useInactivity = (onInactivity, timeout = 100000) => {
  const timerRef = useRef(null);

  const resetTimer = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(onInactivity, timeout);
  };

  useEffect(() => {
    const handleUserActivity = () => {
      resetTimer();
    };

    // Attach event listeners for user activity
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("scroll", handleUserActivity);
    window.addEventListener("click", handleUserActivity);

    // Start the initial timer
    resetTimer();

    // Cleanup event listeners on unmount
    return () => {
      clearTimeout(timerRef.current);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("scroll", handleUserActivity);
      window.removeEventListener("click", handleUserActivity);
    };
  }, [onInactivity, timeout]);
};

export default useInactivity;
