import React, { useState } from "react";
import "../App.css";
import "./css/essentials.css";
import "./css/edene.css";
import "./css/pass.css";
import FlowerGen from "./flowerGen";
import { useNavigate } from "react-router-dom";

// Function to convert month number to Roman numeral
const monthToRoman = (month) => {
  const romanNumerals = [
    "",
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII",
    "IX",
    "X",
    "XI",
    "XII",
  ];
  return romanNumerals[month];
};

const Pass = ({ questionsCompleted }) => {
  const [finalize, setFinalize] = useState(null);
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [buttonTimeout, setButtonTimeout] = useState(false);

  const handleNext = () => {
    setFinalize(true);
  };

  const handlePrint = () => {
    window.print();
    setLoadingPrint(true);
    setButtonTimeout(true);
    setTimeout(() => {
      setButtonTimeout(false);
    }, 10000);
    setTimeout(() => {
      setLoadingPrint(false);
      window.location.reload();
    }, 40000);
  };

  const navigate = useNavigate();

  const visitGarden = () => {
    navigate("/garden");
  };

  const visitAbout = () => {
    navigate("/about");
  };

  return (
    <div id="edene-wrapper" className="edene-wrapper">
      {finalize !== true ? (
        <div className="edene-title-container move-up">
          <div className="edene-title">
            You HAVE BEEN <br />
            SUCCESSFULLY NATURALIZED!
          </div>

          <div className="edene-title-action" style={{ marginTop: "50px" }}>
            YOU ARE NOW AN OFFICIAL CITIZEN OF IDEM. YOUR PASSFLEUR HAS <br />
            BEEN ISSUED. THE BOARD MEMBERS OF IDEM EXTEND THEIR WARMEST <br />
            CONGRATULATIONS AND WELCOME YOU TO OUR GARDEN COMMUNITY.
          </div>

          <div>
            <div className="andale">
              <div
                onClick={() => handleNext()}
                className="edene-action-button andale move-down"
              >
                NEXT
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="edene-title-container passport">
          <div>
            <div className="pass-container">
              <div className="pass-header">
                <div className="pass-header-title">GARDEN oF IDEM</div>
                <p>OFFICIAL PASSFLEUR BY THE GARDEN OF IDEM</p>
              </div>
              <div className="pass-body">
                <div className="flower-image">
                  <FlowerGen
                    svgFiles={questionsCompleted.files}
                    scaleFactor={1.9}
                  />
                </div>
                <div className="pass-info">
                  <div className="info">
                    <div className="value">
                      {questionsCompleted.name.toUpperCase()}
                    </div>
                    <span className="label">NAME OF THE HOLDER</span>
                  </div>
                  <div className="info">
                    <div className="value">{questionsCompleted.code}</div>
                    <span className="label">FLOWER CODE</span>
                  </div>
                  <div className="info">
                    <div className="value">
                      {`${questionsCompleted.date.split("/")[0]}/${monthToRoman(
                        parseInt(questionsCompleted.date.split("/")[1])
                      )}/${questionsCompleted.date.split("/")[2]}`}
                    </div>
                    <span className="label">ISSUING DATE</span>
                  </div>
                  <div className="signature">
                    <span>SIGNATURE</span>
                  </div>
                </div>
              </div>
              <div className="pass-footer">
                <div className="footer-left">
                  <span>NATURALIZATION ANSWER CODE</span>
                </div>
                <div className="footer-right">
                  {questionsCompleted.files.map((file, index) => (
                    <span key={index}>{file.fileName}</span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="pass-actions">
            {loadingPrint ? (
              <div className="edene-action-button andale alternative-button full-width-button disabled">
                PRINTING...
              </div>
            ) : (
              <div
                onClick={() => handlePrint()}
                className="edene-action-button andale alternative-button full-width-button"
              >
                PRINT
              </div>
            )}

            {buttonTimeout ? (
              <>
                <div className="edene-action-button andale alternative-button half-width-button disabled">
                  VISIT GARDEN
                </div>
                <div className="edene-action-button andale alternative-button half-width-button disabled">
                  ABOUT IDEM
                </div>
              </>
            ) : (
              <>
                <div
                  onClick={() => visitGarden()}
                  className="edene-action-button andale alternative-button half-width-button"
                >
                  VISIT GARDEN
                </div>
                <div
                  onClick={() => visitAbout()}
                  className="edene-action-button andale alternative-button half-width-button"
                >
                  ABOUT IDEM
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Pass;
