import React, { useState } from "react";
import "./css/edene.css";
import { useNavigate } from "react-router-dom";
import arrow from "../assets/images/arrow.png";

const Menu = () => {
  const navigate = useNavigate();

  const handleMenuClick = () => {
    navigate("/");
  };

  return (
    <div className="edene-menu">
      <div
        className="edene-action-button andale"
        onClick={() => handleMenuClick()}
      >
        <img src={arrow} alt="" />
      </div>
    </div>
  );
};

export default Menu;
