import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import "./css/edene.css";
import "./css/essentials.css";
import Menu from "./menu";
import MenuConfirmation from "./menuConfirmation";

const Onboarding = ({ onData }) => {
  const [start, setStart] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [credentials, setCredentials] = useState(false);
  const [completed, setCompleted] = useState(false);

  const navigate = useNavigate(); // Hook for navigation

  const handleCompletion = () => {
    if (inputValue.length > 2) {
      setCompleted(true);
      onData(inputValue); // Send data back to Landing
    } else {
      setError("Name is too short");
    }
  };

  // NAME SAVING LOGIC
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (e.target.value.length > 2) {
      setError("");
    } else {
      setError("Name is too short");
    }
  };

  return (
    <div>
      <div className="edene-wrapper">
        <div className="background"></div>

        <div className="edene-title-container">
          {!start && !confirmation && !credentials && !completed ? (
            <>
              <Menu></Menu>
              <div className="edene-title edene-onboarding-title">
                gARDEN of Idem
              </div>

              <div className="edene-action-circle-container">
                <div
                  className="edene-action-circle andale"
                  onClick={() => navigate("/garden")} // Navigate to /garden
                >
                  VISIT GARDEN
                </div>
                <div
                  className="edene-action-circle andale"
                  onClick={() => setStart(true)}
                >
                  GET NATURALISED
                </div>
                <div
                  className="edene-action-circle andale"
                  onClick={() => navigate("/about")} // Navigate to /about
                >
                  ABOUT
                </div>
              </div>
            </>
          ) : null}

          {start && !confirmation && !credentials && !completed ? (
            <div className="edene-onboarding-container">
              <Menu></Menu>
              <div className="edene-body-text andale">
                WE APPRECIATE YOUR INTEREST IN OBTAINING CITIZENSHIP IN IDEM.{" "}
                <br />
                <br />
                IDEM REPRESENTS AN UTOPIAN GARDEN FUNCTIONING AS A GOVERNMENTAL
                ENTITY, OFFERING REFUGE TO INDIVIDUALS SEEKING A NEW PLACE TO
                BELONG. ROOTED IN THE PHILOSOPHICAL DOCTRINE OF COSMOPOLITANISM,
                IDEM’S GOVERNANCE IS BASED ON AN INCLUSIVE POLICY, WELCOMING
                ANYBODY WHO ARRIVES THROUGH PEACEFUL MEANS. IDEM’S CITIZENS
                TRANSCEND THE HUMAN BEING, TRANSLATING PEOPLE’S SET OF VALUES
                AND PRINCIPLES INTO UNIQUE FLOWERS. <br />
                <br />
                TO BE PART OF IDEM’S GARDEN, INDIVIDUALS MUST GO THROUGH A
                NATURALISATION PROCESS, WHICH WILL LASTS APPROXIMATELY TEN
                MINUTES. DURING THIS TIME, THE REGISTRATION OFFICE WILL ASSESS
                YOUR INTEGRATION STATUS AND ELIGIBILITY THROUGH A SERIES OF
                INQUIRIES.
              </div>

              <div className="edene-action-button-container andale">
                <div></div>
                <div
                  className="edene-action-button andale"
                  onClick={() => setConfirmation(true)}
                >
                  CONTINUE
                </div>
              </div>
            </div>
          ) : null}

          {start && confirmation && !credentials && !completed ? (
            <div className="edene-onboarding-container">
              <Menu></Menu>
              <div className="edene-body-text andale">
                YOU CAN CHOOSE ONLY ONE ANSWER PER QUESTION, PRIORITISING WHAT
                SEEMS MOST CORRECT FOR YOU OR RESONATES CLOSEST TO YOU. UNDER
                THE “LEARN MORE” BUTTON YOU CAN READ MORE ABOUT TOPICS REGARDING
                NATURALISATION PROCESSES IN SWITZERLAND. <br />
                <br />
                IT IS IMPERATIVE THAT YOU RESPOND HONESTLY AND ADHERE TO THE
                TRUTH AS INTEGRITY IS ESSENTIAL THROUGHOUT THIS PROCESS.EACH
                ANSWER EQUALS TO ONE PART OF YOUR PASSFLEUR, ULTIMATELY MAKING
                UP YOUR UNIQUE DOCUMENT.
                <br />
                <br />
                ONCE YOUR PASSFLEUR IS ISSUED, YOUR WILL OFFICIALLY BE
                RECOGNISED AS A NEW CITIZEN OF IDEM.
              </div>

              <div className="edene-action-button-container andale">
                <div
                  className="edene-action-button andale"
                  onClick={() => setConfirmation(false)}
                >
                  BACK
                </div>
                <div
                  className="edene-action-button andale"
                  onClick={() => setCredentials(true)}
                >
                  START
                </div>
              </div>
            </div>
          ) : null}

          {start && confirmation && credentials && !completed ? (
            <div className="edene-onboarding-container">
              <Menu></Menu>
              <div className="edene-body-text andale center heading2">
                ENTER YOUR NAME:
              </div>
              <br />
              <br />
              <br />
              <br />
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
              />
              {error && <div className="error-message">{error}</div>}

              <div className="edene-action-button-container andale center-override">
                <div
                  className="edene-action-button andale"
                  onClick={handleCompletion}
                >
                  CONFIRM
                </div>
              </div>
            </div>
          ) : null}

          {start && confirmation && credentials && completed ? (
            <div className="edene-onboarding-container">
              <div className="edene-body-text andale center">Loading....</div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
