import React, { useState } from "react";
import "../App.css";
import "./css/edene.css";
import "./css/essentials.css";
import "./css/team.css";

// Import team member images
import artDirector from "../assets/people/person_1.png";
import developer from "../assets/people/person_2.png";
import graphicDesigner from "../assets/people/person_3.png";
import mediaDesigner from "../assets/people/person_4.png";
import industrialDesigner from "../assets/people/person_5.png";

const TeamMobile = () => {
  const [expandedMember, setExpandedMember] = useState(null);

  const toggleDescription = (key) => {
    setExpandedMember(expandedMember === key ? null : key);
  };

  return (
    <div>
      <div className="edene-title-mobile">PASSFLEUR TEAm</div>
      <div className="team-container-mobile">
        <div className="team-member-mobile">
          <div className="team-subtitle-mobile">ART DIRECTION</div>
          <img
            className="mobile-img"
            src={artDirector}
            alt="Art Director & Conceptual Design"
          />
          <div className="team-name-mobile">ARDENNES FATIMAH oRNATI</div>
          <div className="team-subtitle-mobile">
            CONCEPTUAL DESIGNER & PHOTOGRAPHER
          </div>
          <div className="team-body-mobile">
            Upon attaining Swiss citizenship and acquiring her third
            nationality, the exploration of cultural identity emerged as a
            central theme in both her personal reflections and professional
            endeavors. Ardennes is committed to integrating conceptual design
            with social and collaborative practices, placing interdisciplinary
            approaches at the forefront of her work.
          </div>
          <div className="team-links-mobile">
            <a href="mailto:fatiardennes@gmail.com">Email</a>
            <a href="https://ornati.space/">Website</a>
            <a href="https://www.instagram.com/ornati.ardennes/">Instagram</a>
            <a href="https://www.linkedin.com/in/ardennes-fatimah-ornati-2296b8213/">
              LinkedIn
            </a>
          </div>
        </div>

        <div className="team-member-mobile">
          <div className="team-subtitle-mobile">WEB DEVELOPMENT</div>
          <img className="mobile-img" src={developer} alt="Developer" />
          <div className="team-name-mobile">AnDREAS KoHLER</div>
          <div className="team-subtitle-mobile">INTERACTION DESIGNER</div>
          <div className="team-body-mobile">
            After completing a comprehensive software engineering apprenticeship
            and gaining valuable experience as a frontend developer, Andy
            shifted his focus towards creative and design-oriented goals. By
            pursuing a degree in Interaction Design, he seamlessly integrates
            his technical development skills with innovative design solutions.
          </div>
          <div className="team-links-mobile">
            {" "}
            <a href="mailto:andykohl3r@gmail.com">Email</a>
            <a href="https://www.instagram.com/andy.scss/">Instagram</a>
            <a href="https://www.linkedin.com/in/andreas-kohler-0944111a2/">
              LinkedIn
            </a>
          </div>
        </div>

        <div className="team-member-mobile">
          <div className="team-subtitle-mobile">FLOWER DESIGN</div>
          <img
            className="mobile-img"
            src={graphicDesigner}
            alt="Graphic Designer"
          />
          <div className="team-name-mobile">NIL SAEGESSER</div>
          <div className="team-subtitle-mobile">GRAPHIC DESIGNER</div>
          <div className="team-body-mobile">
            Nil is a graphic designer and visual artist who explores visual
            language and creates synergies between different themes. Her main
            focus is visual interaction and capturing different perspectives and
            realities of her environment.
          </div>
          <div className="team-links-mobile">
            <a href="mailto:nil.saegesser@gmail.com">Email</a>
            <a href="https://www.nilsaegesser.ch/">Website</a>
            <a href="https://www.instagram.com/nil.saegesser/">Instagram</a>
            <a href="https://www.linkedin.com/in/nil-saegesser-9aa9b021a/">
              LinkedIn
            </a>
          </div>
        </div>

        <div className="team-member-mobile">
          <div className="team-subtitle-mobile">USER EXPERIENCE</div>
          <img
            className="mobile-img"
            src={mediaDesigner}
            alt="Media Designer"
          />
          <div className="team-name-mobile">FERDI PINARCI</div>
          <div className="team-subtitle-mobile">INTERACTIVE MEDIA DESIGNER</div>
          <div className="team-body-mobile">
            After a 4-year apprenticeship in Interactive Media Design, Ferdi
            pursued a Bachelor's in Game Design at ZHdK, focusing on
            interdisciplinary design and the intersection of design and code.
            His diverse background enables him to create visually striking,
            technically advanced products in generative design, motion design,
            3D graphics, visual identities, and interactive digital art.
          </div>
          <div className="team-links-mobile">
            <a href="mailto:info@ferdipinarci.com">Email</a>
            <a href="https://www.ferdipinarci.com/">Portfolio</a>
            <a href="https://www.instagram.com/pinarciferdi/">Instagram</a>
          </div>
        </div>

        <div className="team-member-mobile">
          <div className="team-subtitle-mobile">INSTALLATION</div>
          <img
            className="mobile-img"
            src={industrialDesigner}
            alt="Industrial Designer"
          />
          <div className="team-name-mobile">LoUIS WIDMER</div>
          <div className="team-subtitle-mobile">INDUSTRIAL DESIGNER</div>
          <div className="team-body-mobile">
            Louis is an Industrial Designer captivated by emerging technologies
            and natural form language. He explores sustainable materials and
            evolving aesthetics, integrating cutting-edge tech with
            nature-inspired design to create forward-thinking, eco-conscious
            works.
          </div>
          <div className="team-links-mobile">
            {" "}
            <a href="mailto:louiswidmer01@gmail.com">Email</a>
            <a href="https://www.instagram.com/louisultralight/">Instagram</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMobile;
