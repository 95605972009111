import React, { useState } from "react";
import "./css/edene.css";
import { useNavigate } from "react-router-dom";
import arrow from "../assets/images/arrow.png";

const MenuConfirmation = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();

  const handleMenuClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirm = () => {
    setShowConfirmation(false);
    navigate("/");
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  return (
    <div className="edene-menu">
      <div
        className="edene-action-button andale"
        onClick={() => handleMenuClick()}
      >
        <img src={arrow} alt="" />
      </div>

      {showConfirmation && (
        <div className="confirmation-dialog">
          <div className="confirmation-content">
            <div className="confirmation-title">Attention:</div>
            <div className="confirmation-message">
              BY EXITING THE PROCESS YOU WILL WITHDRAW YOUR APPLICATION AND YOUR
              PROGRESS WILL BE ERASED. DO YOU STILL WANT TO CONTINUE?
            </div>
            <div className="edene-action-button-container andale confirmation-buttons">
              <div
                onClick={handleCancel}
                className="edene-action-button andale"
              >
                NO
              </div>
              <div
                onClick={handleConfirm}
                className="edene-action-button andale"
              >
                YES
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuConfirmation;
