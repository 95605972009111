export const questions = [
  {
    id: 1,
    name: "Q01 MoTIVATIoN CHECK",
    title: "WHAT IS YOUR MOTIVE IN SEEKING NATURALIZATION?",
    flower: "Pistils",
    answer_ids: ["Q01.1m", "Q01.1s", "Q01.1u", "Q01.1c", "Q01.1f"],
    learn_more: "learn_more1",
  },

  {
    id: 2,
    name: "Q02 INTEGRITY EVALUATIoN",
    title:
      "WHAT DO YOU BELIEVE IS THE MOST IMPORTANT VALUE FOR LIVING HARMONIOUSLY?",
    flower: "Internal Petals",
    answer_ids: ["Q02.2u", "Q02.2f", "Q02.2s", "Q02.2c", "Q02.2m"],
    learn_more: "learn_more2",
  },

  {
    id: 3,
    name: "Q03 integration inspection",
    title:
      "WHICH FACTOR MAKES YOU MORE ELIGIBLE FOR NATURALISATION INTO IDEM’S GARDEN? ",
    flower: "Mid-center petals",
    answer_ids: ["Q03.3u", "Q03.3f", "Q03.3s", "Q03.3c", "Q03.3m"],
    learn_more: "learn_more3",
  },

  {
    id: 4,
    name: "Q04 future assessment",
    title: "HOW ARE YOU PLANNING TO CONTRIBUTE TO IDEM’S GARDEN?",
    flower: "External Petals",
    answer_ids: ["Q04.4f", "Q04.4u", "Q04.4m", "Q04.4c", "Q04.4s"],
    learn_more: "learn_more4",
  },

  {
    id: 5,
    name: "Q05 CITIZENSHIP ENTITLEMENT",
    title:
      "WHAT DO YOU BELIEVE INCLUSION AND BELONGING SHOULD BE BASED ON WHEN EVALUATING CITIZENSHIP RIGHTS?",
    flower: "Pistils",
    answer_ids: ["Q05.5u", "Q05.5m", "Q05.5s", "Q05.5f", "Q05.5c"],
    learn_more: "learn_more5",
  },
];

export const answers = [
  // ANSWERS TO QUSTION 1
  {
    id: "Q01.1m",
    title: "FINANCIAL AND PROFESSIONAL OPPORTUNITIES",
    type: "America",
  },
  {
    id: "Q01.1s",
    title: "ESCAPE FROM POLITICAL PERSECUTION",
    type: "Asia",
  },
  {
    id: "Q01.1u",
    title: "THE DESIRE FOR CULTURAL INTEGRATION",
    type: "Europe",
  },
  {
    id: "Q01.1c",
    title: "FAMILY REUNIFICATION",
    type: "Oceania",
  },
  {
    id: "Q01.1f",
    title: "TO FIND A SENSE OF BELONGING",
    type: "Africa",
  },
  // ANSWERS TO QUSTION 2
  {
    id: "Q02.2u",
    title: "RESPECT",
    type: "Europe",
  },
  {
    id: "Q02.2f",
    title: "EMPATHY",
    type: "Africa",
  },
  {
    id: "Q02.2s",
    title: "COMMUNICATION",
    type: "Asia",
  },
  {
    id: "Q02.2c",
    title: "RESPONSIBILITY",
    type: "Oceania",
  },
  {
    id: "Q02.2m",
    title: "TRUSTWORTHINESS",
    type: "America",
  },
  // ANSWERS TO QUSTION 3
  {
    id: "Q03.3u",
    title: "I HAVE ALWAYS PAID TAXES AND VOTED",
    type: "Europe",
  },
  {
    id: "Q03.3f",
    title: "I JUST FEEL IT’S RIGHT",
    type: "Africa",
  },
  {
    id: "Q03.3s",
    title: "MY FRIENDS TELL ME I AM INTEGRATED ENOUGH",
    type: "Asia",
  },
  {
    id: "Q03.3c",
    title: "I DO NOT HAVE ANY CRIMINAL RECORD",
    type: "Oceania",
  },
  {
    id: "Q03.3m",
    title: "I KNOW ALL ABOUT IDEM’S HISTORY",
    type: "America",
  },
  // ANSWERS TO QUSTION 4
  {
    id: "Q04.4f",
    title: "DOING CHARITY WORK",
    type: "Africa",
  },
  {
    id: "Q04.4u",
    title: "RUNNING FOR PRESIDENT",
    type: "Europe",
  },
  {
    id: "Q04.4m",
    title: "SUPPORTING FUTURE GENERATIONS",
    type: "America",
  },
  {
    id: "Q04.4c",
    title: "FIGHTING AGAINST CLIMATE CHANGE",
    type: "Oceania",
  },
  {
    id: "Q04.4s",
    title: "PASSING DOWN IDEM’S TRADITIONS",
    type: "Asia",
  },
  // ANSWERS TO QUSTION 5
  {
    id: "Q05.5u",
    title: "RESIDENTIAL RIGHTS (I LIVE HERE)",
    type: "Europe",
  },
  {
    id: "Q05.5m",
    title: "INDIVIDUAL RIGHTS (I WISH/DESIRE TO)",
    type: "America",
  },
  {
    id: "Q05.5s",
    title: "CULTURAL RIGHTS (I GREW UP HERE)",
    type: "Asia",
  },
  {
    id: "Q05.5f",
    title: "BLOOD RIGHTS (MY LINEAGE COMES FROM HERE)",
    type: "Africa",
  },
  {
    id: "Q05.5c",
    title: "BIRTH RIGHTS (I WAS BORN HERE)",
    type: "Oceania",
  },
];
