import React, { useState, useEffect } from "react";
import "./css/loading.css";

const LoadingBar = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let startTime = null;
    const duration = 3000; // 3 seconds

    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;

      // Non-linear progression using an ease-out function
      const newProgress = Math.min(
        100,
        (elapsed / duration) * (2 - elapsed / duration) * 100
      );

      setProgress(newProgress);

      if (elapsed < duration) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);
  }, []);

  return (
    <div className="container">
      <progress className="progress-bar" value={progress} max="100"></progress>
    </div>
  );
};

export default LoadingBar;
