import React, { useState, useEffect, useRef } from "react";
import "./css/mobile.css";
import Title from "./subtitle";
import { useNavigate } from "react-router-dom";

const Mobile = () => {
  const navigate = useNavigate();

  return (
    <div className="edene-mobile-wrapper">
      <div className="background-mobile"></div>
      <Title></Title>

      <div className="edene-about-mobile">
        <div
          className="edene-title-mobile overview-title-mobile"
          onClick={() => navigate("/mobile-garden")}
        >
          Visit GArden of IDEm
        </div>
        <div className="divider"></div>
        <div
          className="edene-title-mobile overview-title-mobile"
          onClick={() => navigate("/mobile-about")}
        >
          About the project
        </div>
      </div>
    </div>
  );
};

export default Mobile;
