// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDocs } from "firebase/firestore";
import { collection } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAXEp22yw6FxuXvOdbxGBtuJ5rbyYUjXDg",
  authDomain: "edene-a897b.firebaseapp.com",
  projectId: "edene-a897b",
  storageBucket: "edene-a897b.appspot.com",
  messagingSenderId: "813066635249",
  appId: "1:813066635249:web:fa358f0d9ac6ce08792650",
  measurementId: "G-1DKYJT5VNT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, getDocs, collection };
