import React, { useState } from "react";
import "../App.css";
import "./css/essentials.css";
import "./css/edene.css";
import Onboarding from "./onboarding";
import Questions from "./questions";
import Pass from "./pass";
import LoadingBar from "./progress";
import Menu from "./menuConfirmation";
import Title from "./subtitle";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const navigate = useNavigate();

  const handleStart = () => {
    navigate("/edene");
  };

  return (
    <div>
      <div className="edene-wrapper" onClick={() => handleStart()}>
        <div className="background"></div>
        <Title />
        <div className="edene-title-container">
          <div className="edene-title">
            IF NATIoNS WERE GARDENS <br /> ANd CITIZENS THEIR FLoWERS, <br />
            coULD there be Anyone <br /> who does NoT BELoNG?
          </div>
          <div className="edene-title-action pulsate">TAP TO ENTER</div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
