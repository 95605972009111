import React, { useState, useEffect } from "react";
import "./css/edene.css";
import "./css/essentials.css";

const FlowerGen = ({ svgFiles, scaleFactor }) => {
  const [svgContents, setSvgContents] = useState([]);

  useEffect(() => {
    const loadSVG = async (folder, fileName) => {
      try {
        const module = await import(
          `../assets/flower/${folder}/${fileName}.svg`
        );
        const response = await fetch(module.default);
        if (!response.ok) {
          throw new Error(
            `Failed to fetch SVG file ${fileName} from folder ${folder}`
          );
        }
        let svgContent = await response.text();
        // Apply scaling to SVG content
        svgContent = svgContent.replace(
          /<svg([^>]*)>/,
          `<svg$1 width="${scaleFactor * 100}%" height="${
            scaleFactor * 100
          }%" fill="white">`
        );
        return svgContent;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const fetchSVGs = async () => {
      try {
        const svgContents = await Promise.all(
          svgFiles.map(async ({ folder, fileName }) => {
            return await loadSVG(folder, fileName);
          })
        );
        setSvgContents(svgContents);
      } catch (error) {
        console.error("Error loading SVG files", error);
      }
    };

    fetchSVGs();
  }, [svgFiles, scaleFactor]);

  return (
    <div className="edene-flower-container">
      {svgContents.map((svgContent, index) => (
        <div
          className="edene-flower-layer"
          key={index}
          dangerouslySetInnerHTML={{ __html: svgContent }}
          style={{ marginBottom: "10px" }}
        />
      ))}
    </div>
  );
};

export default FlowerGen;
