// App.js
import React from "react";
import {
  BrowserRouter,
  Route,
  HashRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import Landing from "./components/landing";
import FlowerGen from "./components/flowerGen";
import Garden from "./components/garden";
import About from "./components/about";
import Edene from "./components/edene";
import Mobile from "./components/mobile";
import MobileGarden from "./components/mobile/garden";
import MobileAbout from "./components/mobile/about";
import useInactivity from "./components/inactvitiy";

function App() {
  const handleInactivity = () => {
    window.location.reload();
  };

  useInactivity(handleInactivity, 400000);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />}></Route>
        <Route path="/Edene" element={<Edene />}></Route>
        <Route path="/garden" element={<Garden />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/mobile" element={<Mobile />}></Route>
        <Route path="/mobile-about" element={<MobileAbout />}></Route>
        <Route path="/mobile-garden" element={<MobileGarden />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
