import React, { useState, useEffect, useRef } from "react";
import "../App.css";
import "./css/essentials.css";
import "./css/edene.css";
import "./css/garden.css";
import FlowerGen from "./flowerGen";
import { db } from "./utils/firebase";
import { collection, getDocs, query, orderBy } from "firebase/firestore";

import Menu from "./menu";
import Title from "./subtitle";

const Garden = () => {
  const [passes, setPasses] = useState([]);
  const [latestPass, setLatestPass] = useState(null);
  const flowerRef = useRef(null); // Ref for previous images container
  const [scrollDirection, setScrollDirection] = useState("right");

  useEffect(() => {
    const fetchPasses = async () => {
      try {
        const passesRef = collection(db, "passes");
        const passesQuery = query(passesRef, orderBy("date", "desc"));
        const querySnapshot = await getDocs(passesQuery);

        const passesData = [];
        querySnapshot.forEach((doc) => {
          passesData.push({ id: doc.id, ...doc.data() });
        });

        // Set latest pass and other passes
        if (passesData.length > 0) {
          setLatestPass(passesData[0]); // Latest pass is the first entry
          setPasses(passesData.slice(1)); // Rest of the entries
        } else {
          setLatestPass(null); // No latest pass if there are no entries
          setPasses([]); // No passes
        }

        console.log("Passes Data:", passesData);
      } catch (e) {
        console.error("Error fetching passes:", e);
      }
    };

    fetchPasses();
  }, []);

  useEffect(() => {
    // Automatically scroll the container horizontally
    const scrollInterval = setInterval(() => {
      if (flowerRef.current) {
        if (scrollDirection === "right") {
          flowerRef.current.scrollLeft += 2;
          if (
            flowerRef.current.scrollLeft >=
            flowerRef.current.scrollWidth - flowerRef.current.clientWidth
          ) {
            setScrollDirection("left");
          }
        } else {
          flowerRef.current.scrollLeft -= 2;
          if (flowerRef.current.scrollLeft <= 0) {
            setScrollDirection("right");
          }
        }
      }
    }, 20); // Adjust scroll interval as needed

    return () => clearInterval(scrollInterval); // Cleanup interval on unmount
  }, [scrollDirection]);

  return (
    <div className="edene-wrapper">
      <Menu></Menu>
      <Title></Title>
      <div className="background-garden"></div>
      <div className="edene-title-container">
        <div className="edene-title edene-onboarding-title">gARDEN of Idem</div>
      </div>
      {latestPass && ( // Render latest flower container if latestPass is not null
        <div className="latest-flower-container">
          <div className="latest-flower-name">{latestPass.svgFiles.name}</div>
          <div className="latest-flower">
            <FlowerGen svgFiles={latestPass.svgFiles.files} scaleFactor={1} />
          </div>
          <div className="latest-flower-info">
            ISSUING DATE <br /> {latestPass.svgFiles.date} <br /> <br />
            FLOWER CODE <br /> {latestPass.svgFiles.code}
          </div>
        </div>
      )}
      <div className="flower-count">
        TOTAL NATURALIZED CITIZENS: {passes.length + 271}
      </div>
      <div ref={flowerRef} className="flower-carousel">
        {passes.map((pass) => (
          <div className="flower-wrapper" key={pass.id}>
            <FlowerGen svgFiles={pass.svgFiles.files} scaleFactor={1} />
            <div className="flower-code">{pass.svgFiles.code}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Garden;
