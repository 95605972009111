import React from "react";
import "./css/edene.css";

const Title = () => {
  return (
    <div className="edene-title-action edene-slogan andale">
      OFFICIAL REGISTRATION OFFICE PORTAL
    </div>
  );
};

export default Title;
