import React, { useState } from "react";
import "../App.css";
import "./css/edene.css";
import "./css/essentials.css";
import "./css/team.css";

// Import team member images
import artDirector from "../assets/people/person_1.png";
import developer from "../assets/people/person_2.png";
import graphicDesigner from "../assets/people/person_3.png";
import mediaDesigner from "../assets/people/person_4.png";
import industrialDesigner from "../assets/people/person_5.png";

const Team = () => {
  const [expandedMember, setExpandedMember] = useState(null);

  const toggleDescription = (key) => {
    setExpandedMember(expandedMember === key ? null : key);
  };

  return (
    <div>
      <div className="edene-title edene-about-title">PASSFLEUR TEAm</div>
      <div className="team-container">
        <div className="team-member">
          <div className="team-name">ARDENNES FATIMAH oRNATI</div>
          <img
            className="pulsate"
            src={artDirector}
            alt="Art Director & Conceptual Design"
            onClick={() => toggleDescription("artDirector")}
          />
          <div
            className={`team-description ${
              expandedMember === "artDirector" ? "expanded" : "collapsed"
            }`}
          >
            <div className="team-title">
              {expandedMember === "artDirector"
                ? "CONCEPTUAL DESIGNER & PHOTOGRAPHER"
                : "ART DIRECTION"}
            </div>
            <div className="team-body">
              Upon attaining Swiss citizenship and acquiring her third
              nationality, the exploration of cultural identity emerged as a
              central theme in both her personal reflections and professional
              endeavors. Ardennes is committed to integrating conceptual design
              with social and collaborative practices, placing interdisciplinary
              approaches at the forefront of her work.
            </div>
            <div className="team-links">
              www.ornati.space | fatiardennes@gmail.com
            </div>
          </div>
        </div>
        <div className="team-member">
          <div className="team-name">AnDREAS KoHLER</div>
          <img
            className="pulsate"
            src={developer}
            alt="Developer"
            onClick={() => toggleDescription("developer")}
          />
          <div
            className={`team-description ${
              expandedMember === "developer" ? "expanded" : "collapsed"
            }`}
          >
            <div className="team-title">
              {expandedMember === "developer"
                ? "INTERACTION DESIGNER"
                : "WEB DEVELOPMENT"}
            </div>
            <div className="team-body">
              After completing a comprehensive software engineering
              apprenticeship and gaining valuable experience as a frontend
              developer, Andy shifted his focus towards creative and
              design-oriented goals. By pursuing a degree in Interaction Design,
              he seamlessly integrates his technical development skills with
              innovative design solutions.
            </div>
            <div className="team-links">@andy.scss | andykohl3r@gmail.com</div>
          </div>
        </div>
        <div className="team-member">
          <div className="team-name">NIL SAEGESSER</div>
          <img
            className="pulsate"
            src={graphicDesigner}
            alt="Graphic Designer"
            onClick={() => toggleDescription("graphicDesigner")}
          />
          <div
            className={`team-description ${
              expandedMember === "graphicDesigner" ? "expanded" : "collapsed"
            }`}
          >
            <div className="team-title">
              {expandedMember === "graphicDesigner"
                ? "GRAPHIC DESIGNER"
                : "FLOWER DESIGN"}
            </div>
            <div className="team-body">
              Nil is a graphic designer and visual artist who explores visual
              language and creates synergies between different themes. Her main
              focus is visual interaction and capturing different perspectives
              and realities of her environment.
            </div>
            <div className="team-links">
              www.nilsaegesser.ch | nil.saegesser@gmail.com
            </div>
          </div>
        </div>
        <div className="team-member">
          <div className="team-name">FERDI PINARCI</div>
          <img
            className="pulsate"
            src={mediaDesigner}
            alt="Media Designer"
            onClick={() => toggleDescription("mediaDesigner")}
          />
          <div
            className={`team-description ${
              expandedMember === "mediaDesigner" ? "expanded" : "collapsed"
            }`}
          >
            <div className="team-title">
              {expandedMember === "mediaDesigner"
                ? "INTERACTIVE MEDIA DESIGNER"
                : "USER EXPERIENCE"}
            </div>
            <div className="team-body">
              After a 4-year apprenticeship in Interactive Media Design, Ferdi
              pursued a Bachelor's in Game Design at ZHdK, focusing on
              interdisciplinary design and the intersection of design and code.
              His diverse background enables him to create visually striking,
              technically advanced products in generative design, motion design,
              3D graphics, visual identities, and interactive digital art.
            </div>
            <div className="team-links">
              www.ferdipinarci.com | info@ferdipinarci.com
            </div>
          </div>
        </div>
        <div className="team-member">
          <div className="team-name">LoUIS WIDMER</div>
          <img
            className="pulsate"
            src={industrialDesigner}
            alt="Industrial Designer"
            onClick={() => toggleDescription("industrialDesigner")}
          />
          <div
            className={`team-description ${
              expandedMember === "industrialDesigner" ? "expanded" : "collapsed"
            }`}
          >
            <div className="team-title">
              {expandedMember === "industrialDesigner"
                ? "INDUSTRIAL DESIGNER"
                : "INSTALLATION"}
            </div>
            <div className="team-body">
              Louis is an Industrial Designer captivated by emerging
              technologies and natural form language. He explores sustainable
              materials and evolving aesthetics, integrating cutting-edge tech
              with nature-inspired design to create forward-thinking,
              eco-conscious works.
            </div>
            <div className="team-links">
              @louisultralight | louiswidmer01@gmail.com
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
